import React from "react";
import { OdysseeContext } from "./context";
import { useParams } from "react-router";
import { useGetOdysseeModulesQuery, useGetOdysseeQuery, useGetOdysseeRessourcesQuery } from "../../store/api/odyssee";
import { useAuthToken } from "../../hooks/auth";
import useOdysseeNavigation from "../odysseeNavigation/useOdyssee";
import { ODYSSEE_PAGES } from "../odysseeNavigation";

export const getStepPosition = (stepId, modules) => {
	let stepIndex = 0;
	const moduleIndex = modules?.findIndex((module) => {
		const stepIdx = module?.lecon?.findIndex((lecon) => lecon.id === stepId);
		if (stepIdx > -1) {
			stepIndex = stepIdx;
		}
		return stepIdx > -1;
	});
	return { moduleIndex, stepIndex };
};

export const computeStepsBeforeModuleIndex = (moduleIndex, modules) => {
	let index = 0;

	modules.slice(0, moduleIndex).forEach((module) => {
		index += module?.lecon?.length;
	});
	return index;
};

const OdysseeContextProvider = ({ idOdyssee, children }) => {
	const { odysseeId: idFromParams } = useParams();
	const odysseeId = idOdyssee ?? idFromParams; // override for the case of the id provided in props instead of searchParams.
	const [currentModule, setCurrentModule] = React.useState(null);
	const [currentStep, setCurrentStep] = React.useState(null);
	//TODO: compute initial value
	const [savedStepPosition, setSavedStepPosition] = React.useState(0);
	//Updated this value only if database value changes
	const [databaseStepPosition, setDatabaseStepPosition] = React.useState(0);
	const authToken = useAuthToken();
	const [currentPage, setCurrentPage] = React.useState(ODYSSEE_PAGES.Main);

	const { data: odyssee, refetch: refetchOdyssee } = useGetOdysseeQuery(
		{ idOdyssee: odysseeId, authToken },
		{ skip: !odysseeId || !authToken }
	);

	React.useEffect(() => {
		if (!!odyssee) {
			setDatabaseStepPosition(odyssee?.etape || 0);
			if (!savedStepPosition) {
				setSavedStepPosition(odyssee?.etape || 0);
			}
		}
	}, [odyssee]);

	const { data: modules } = useGetOdysseeModulesQuery(
		{ idOdyssee: odysseeId, authToken },
		{ skip: !odysseeId || !authToken }
	);

	const { data: ressources } = useGetOdysseeRessourcesQuery(
		{ stepId: currentStep?.id, authToken },
		{ skip: !currentStep?.id || !authToken }
	);

	/**
	 * Init saved step position if user hasn't init odyssée
	 */
	React.useEffect(() => {
		if (savedStepPosition === 0 && !!modules) {
			setSavedStepPosition(modules[0]?.lecon?.[0]?.id);
		}
	}, [savedStepPosition, modules]);

	/**
	 * Compute position saved in database
	 */
	const { databaseModuleIndex, databaseStepIndex } = React.useMemo(() => {
		if (!modules) {
			return { databaseModuleIndex: 0, databaseStepIndex: 0 };
		}
		if (databaseStepPosition === 0) {
			return { databaseModuleIndex: 0, databaseStepIndex: 0 };
		}
		const { moduleIndex, stepIndex } = getStepPosition(databaseStepPosition, modules);
		return { databaseModuleIndex: moduleIndex, databaseStepIndex: stepIndex };
	}, [databaseStepPosition, modules]);

	const currentModuleIndex = React.useMemo(() => {
		if (!currentModule) return 0;
		return modules?.findIndex((mod) => mod.id === currentModule.id);
	}, [currentModule, modules]);

	const { progressModuleIndex, progressStepIndexInModule, progressModuleId } = React.useMemo(() => {
		if (!modules || !savedStepPosition)
			return { progressModuleIndex: 0, progressStepIndexInModule: 0, progressModuleId: 0 };
		if (currentPage === ODYSSEE_PAGES.Summary) {
			const idx = modules?.findIndex((mod) => mod.id === currentModule?.id);
			return {
				progressModuleIndex: idx,
				progressStepIndexInModule: 0,
				progressModuleId: modules[idx]?.id,
			};
		}
		const { moduleIndex, stepIndex } = getStepPosition(savedStepPosition, modules);
		return {
			progressModuleIndex: moduleIndex,
			progressStepIndexInModule: stepIndex,
			progressModuleId: modules[stepIndex]?.id,
		};
	}, [modules, savedStepPosition, currentModule, currentPage]);

	const values = React.useMemo(
		() => ({
			odyssee,
			modules,
			currentModule,
			setCurrentModule,
			progressModuleIndex,
			progressStepIndexInModule,
			progressModuleId,
			currentModuleIndex,
			setCurrentStep,
			currentStep,
			ressources,
			setSavedStepPosition,
			savedStepPosition,
			databaseStepPosition,
			setDatabaseStepPosition,
			databaseModuleIndex,
			databaseStepIndex,
			setCurrentPage,
			refetchOdyssee,
		}),
		[
			odyssee,
			modules,
			currentModule,
			setCurrentModule,
			progressModuleIndex,
			progressStepIndexInModule,
			progressModuleId,
			currentModuleIndex,
			setCurrentStep,
			currentStep,
			ressources,
			setSavedStepPosition,
			savedStepPosition,
			databaseStepPosition,
			setDatabaseStepPosition,
			databaseModuleIndex,
			databaseStepIndex,
			setCurrentPage,
			refetchOdyssee,
		]
	);

	return <OdysseeContext.Provider value={values}>{children}</OdysseeContext.Provider>;
};

export default OdysseeContextProvider;
