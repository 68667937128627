import { Box, styled, Typography, Rating as MuiRating } from "@mui/material";
import { ReactComponent as EmptyStar } from "../../../resources/images/svg/odyssee/star-empty.svg";
import { ReactComponent as FullStar } from "../../../resources/images/svg/odyssee/star-full.svg";
import { ReactComponent as Close } from "../../../resources/images/svg/odyssee/close-white.svg";
import InexButton from "../../InexButton";
import React from "react";
import { useRateOdysseeMutation } from "../../../store/api/odyssee";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import { useAuthToken } from "../../../hooks/auth";
import { useNavigate } from "react-router-dom";

const RatingContainer = styled(Box)(() => {
	return {
		width: 440,
		maxWidth: "100%",
		backgroundColor: "#18122D99",
		border: "1px solid #FFFFFF",
		borderColor: "rgba(255,255,255,0.3)",
		borderRadius: "10px",
		backdropFilter: "blur(130px)",
		padding: "20px 20px",
		"&::after": {
			position: "absolute",
			// content: "''",
			backgroundColor: "rgba(255,255,255,1)",
			left: 0,
			right: 0,
			bottom: 0,
			top: 0,
			borderRadius: "20px",
			opacity: 0.3,
			backdropFilter: "blur(20px)",
		},
	};
});

const StyledText = styled("textarea")(() => {
	return {
		resize: "none",
		backgroundColor: "#EAE9EF1A",
		width: "100%",
		height: 200,
		borderRadius: "10px",
		color: "#FFFFFF",
		outline: "none",
		padding: "10px",
		borderColor: "transparent",
		"&::placeholder": {
			color: "rgba(255,255,255,0.70)",
		},
	};
});

const Rating = ({ setEditingRating, odyssee, fromStep = false, setCurrentPage }) => {
	const [rating, setRating] = React.useState(odyssee?.note || 0);
	const [comment, setComment] = React.useState(odyssee?.remarque || "");
	const [rateOdyssee] = useRateOdysseeMutation();
	const { refetchOdyssee } = useOdyssee();
	const authToken = useAuthToken();
	const navigate = useNavigate();

	const submit = () => {
		rateOdyssee({ odysseeUserId: odyssee?.idodysseeuser, authToken, note: rating, remarque: comment }).then(() => {
			refetchOdyssee();
			onClose();
			if (!fromStep) {
				setCurrentPage(3);
			}
		});
	};

	const onClose = () => {
		setEditingRating(false);
	};

	return (
		<RatingContainer>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography fontFamily={"Branding Bold"} color={"#FFFFFF"} fontSize={16}>
					Vous aimez cette odyssée ? Notez-la !
				</Typography>
				<Box sx={{ cursor: "pointer" }} onClick={onClose}>
					<Close />
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 3 }}>
				<MuiRating
					name="customized-color"
					defaultValue={0}
					getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
					precision={1}
					icon={<FullStar fontSize="inherit" />}
					emptyIcon={<EmptyStar fontSize="inherit" />}
					value={rating}
					onChange={(event, newValue) => {
						setRating(newValue);
					}}
				/>
			</Box>
			<StyledText
				rows="4"
				style={{}}
				placeholder="Si vous le souhaitez, vous pouvez nous laisser un commentaire "
				value={comment}
				onChange={(event) => {
					setComment(event.target.value);
				}}
			></StyledText>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<InexButton
					onClick={submit}
					variant={"text"}
					textWithGradient={false}
					text={"Envoyer"}
					textSx={{
						color: "white",
					}}
					sx={{
						backgroundColor: "#EF7D8F",
						width: 230,
						mb: 3,
						mt: 3,
					}}
				/>
			</Box>
		</RatingContainer>
	);
};

export default Rating;
