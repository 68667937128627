import React from "react";
import { Box, Typography, Grid, styled, Stack } from "@mui/material";
import { useGetAllOthersOdysseeQuery } from "../../../../store/api/odyssee";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAuthToken } from "../../../../hooks/auth";
import InexButton from "../../../InexButton";
import { useIsMobile } from "../../../../hooks/responsive";
import OdysseeThemeProvider, { useOdysseeTheme } from "../../../../context/odysseeTheme/OdysseeThemeProvider";
import OdysseeContextProvider from "../../../../context/odyssee";

const Container = styled(Grid)(({ theme }) => ({
	position: "relative",
	maxWidth: "min(1800px, 90vw)",
	width: "100%",
}));

const OdysseeCard = ({ odyssee }) => {
	const isMobile = useIsMobile();

	const [
		background,
		textColor,
		buttonBackground,
		buttonColor,
		buttonHoverBackground,
		buttonHoverColor,
		sloganColor,
		contentColor,
	] = useOdysseeTheme("card", [
		"background",
		"header.title",
		"buttons.primary.background",
		"buttons.primary.textColor",
		"buttons.primary.hover.background",
		"buttons.primary.hover.textColor",
		"header.slogan",
		"header.content",
	]);

	return (
		<Box
			sx={{
				width: "100%",
				background,
				borderRadius: "20px",
				overflow: "hidden",
			}}
		>
			<Grid container sx={{ display: "flex" }} spacing={3}>
				<Grid item md={4} xs={12}>
					<img
						src={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`}
						style={{
							width: "100%",
							height: isMobile ? 80 : "100%",
							objectFit: "cover",
							borderTopLeftRadius: "20px",
							borderBottomLeftRadius: "20px",
							minHeight: "220px",
						}}
					/>
				</Grid>
				<Grid item md={8} xs={12}>
					<Box sx={{ padding: "20px 10px" }}>
						<Stack spacing={2}>
							<Typography color={sloganColor} fontWeight={600} textTransform={"uppercase"} fontSize={18}>
								Pour ne plus jamais dire que vous ne saviez pas !
							</Typography>
							<Typography
								color={textColor}
								fontWeight={700}
								fontSize={24}
								dangerouslySetInnerHTML={{ __html: odyssee?.titre }}
							></Typography>
							<Typography
								color={contentColor}
								fontWeight={500}
								fontSize={16}
								lineHeight={1.2}
								sx={{
									display: "-webkit-box",
									overflow: "hidden",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 8,
								}}
								dangerouslySetInnerHTML={{ __html: odyssee?.description }}
							></Typography>
						</Stack>
						<InexButton
							text={"Découvrir"}
							textWithGradient={false}
							textSx={{
								color: "#182B43",
								fontSize: 16,
								fontWeight: 600,
							}}
							sx={{
								width: "fit-content",
								marginTop: 4,
								background: buttonBackground,
								paddingRight: (theme) => theme.spacing(4),
								paddingLeft: (theme) => theme.spacing(4),
								border: "none",
								transition: "background-color 0.3s ease",
								"& > p": {
									color: buttonColor,
								},
								"&:hover": {
									backgroundColor: buttonHoverBackground,
									border: "none",
									"& > p": {
										color: buttonHoverColor,
									},
								},
							}}
							onClick={() => {
								window.open(`https://www.inexplore.com/odyssee/${odyssee?.slug}`, "_blank").focus();
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const MoreOdyssees = ({}) => {
	const authToken = useAuthToken();
	const { data: odyssees } = useGetAllOthersOdysseeQuery({ authToken });

	return (
		<Container>
			{!!odyssees?.length && (
				<Typography
					variant={"h5"}
					color={"#ffffff"}
					fontWeight={700}
					fontSize={{ xs: 22, md: 28 }}
					mb={3}
					lineHeight={1.2}
				>
					Embarquez pour une nouvelle odyssée
				</Typography>
			)}
			{!!odyssees?.length && (
				<Swiper
					slidesPerView={"auto"}
					spaceBetween={30}
					pagination={{
						clickable: true,
					}}
					navigation={true}
					modules={[Pagination, Navigation]}
					className="more-odyssee-swiper"
					slidesOffsetBefore={0}
					slidesOffsetAfter={30}
					style={{ cursor: "grab", paddingBottom: 60 }}
					initialSlide={0}
				>
					{odyssees?.map((odyssee, index) => (
						<SwiperSlide key={`odyssees-${odyssee?.id}`}>
							<OdysseeContextProvider idOdyssee={odyssee?.id}>
								<OdysseeThemeProvider>
									<OdysseeCard odyssee={odyssee} />
								</OdysseeThemeProvider>
							</OdysseeContextProvider>
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</Container>
	);
};

export default MoreOdyssees;
