import { Box, Fade, Rating as MuiRating, styled } from "@mui/material";
import ProgressOdyssee from "./ProgressOdyssee";
import InexButton from "../../InexButton";
import SummaryCarousel from "./SummaryCarousel";
import React from "react";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { ReactComponent as Ovni } from "../../../resources/images/svg/odyssee/ovni.svg";
import { useIsMobile } from "../../../hooks/responsive";
import Rating from "./Rating";
import { ReactComponent as EmptyStar } from "../../../resources/images/svg/odyssee/star-empty.svg";
import { ReactComponent as FullStar } from "../../../resources/images/svg/odyssee/star-full.svg";
import { useOdysseeTheme } from "../../../context/odysseeTheme/OdysseeThemeProvider";

const ImageContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isDone" })(
	({ imageLoaded, isMobile }) => {
		return {
			maxHeight: 650,
			opacity: +imageLoaded,
			transition: "opacity 0.3s ease",
			position: "relative",
			width: isMobile ? "100%" : "unset",
			display: "flex",
		};
	}
);

const StyledRating = styled(MuiRating)(() => {
	return {
		cursor: "pointer",
		"&.Mui-disabled": {
			opacity: 0.85,
		},
	};
});

const Home = ({ setCurrentPage }) => {
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const isMobile = useIsMobile();
	const [isEditingRating, setEditingRating] = React.useState(false);

	const { odyssee, savedStepPosition } = useOdyssee();
	const { continueOdyssee } = useOdysseeNavigation();

	const [buttonColor, buttonBackground, buttonHoverColor, buttonHoverBackground] = useOdysseeTheme("odyssee", [
		"home.buttons.primary.textColor",
		"home.buttons.primary.background",
		"home.buttons.primary.hover.textColor",
		"home.buttons.primary.hover.background",
	]);
	const [backgroundColor] = useOdysseeTheme("background", ["primary"]);

	return (
		<React.Fragment>
			<Box sx={{ display: "flex", position: "relative", top: isMobile ? 20 : -20, minHeight: 700 }}>
				<ImageContainer>
					<img
						style={{
							maxHeight: 650,
							width: isMobile ? "100%" : "unset",
							opacity: +imageLoaded,
							transition: "opacity 0.3s ease",
							...(isMobile && { objectFit: "cover" }),
						}}
						onLoad={() => setImageLoaded(true)}
						src={`https://medias.inrees.com/img/odyssees/1_${odyssee?.id}.jpg`}
					/>
					{isMobile && (
						<Box
							sx={{
								position: "absolute",
								backgroundColor: "rgba(24,18,45,0.35)",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								opacity: +imageLoaded,
								pointerEvents: "none",
								transition: "opacity 0.3s ease",
							}}
						/>
					)}
				</ImageContainer>
				{!!odyssee && odyssee?.datefin > 0 && (
					<Box
						sx={{
							position: "absolute",
							left: 0,
							top: 70,
							zIndex: 4,
							pointerEvents: isEditingRating || !odyssee?.note ? "auto" : "none",
							maxWidth: "100%",
						}}
					>
						<Fade in={isEditingRating || !odyssee?.note}>
							<div>
								<Rating
									setEditingRating={setEditingRating}
									odyssee={odyssee}
									setCurrentPage={setCurrentPage}
								/>
							</div>
						</Fade>
					</Box>
				)}
				<Box
					sx={{
						position: isMobile ? "absolute" : "relative",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: odyssee?.note > 0 ? 3 : 8,
						opacity: +!!odyssee,
						transition: "opacity 0.3s ease",
						top: 30,
						...(isMobile && { top: 0, left: 0, right: 0, bottom: 0 }),
					}}
				>
					<ProgressOdyssee />
					{!!odyssee?.note && (
						<Box sx={{ cursor: "pointer" }} onClick={() => setEditingRating(true)}>
							<StyledRating
								name="home-rating"
								value={odyssee?.note}
								precision={1}
								icon={<FullStar width={40} fontSize="inherit" />}
								emptyIcon={<EmptyStar width={40} fontSize="inherit" />}
								size="small"
								onChange={() => null}
								disabled
							/>
						</Box>
					)}
					<InexButton
						onClick={continueOdyssee}
						variant={"text"}
						textWithGradient={false}
						text={savedStepPosition === 0 ? "Commencer mon odyssée" : "Poursuivre mon odyssée"}
						textSx={{
							color: buttonColor,
						}}
						sx={{
							background: buttonBackground,
							width: 230,
							border: "none",
							"& svg": {
								color: buttonColor,
							},
							"&:hover": {
								background: buttonHoverBackground,
								border: "none",
								"& > p": {
									color: buttonHoverColor,
								},
								"& svg": {
									color: buttonHoverColor,
								},
							},
						}}
					/>
				</Box>
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						height: 50,
						background: `linear-gradient(360deg, ${backgroundColor} 10.94%, rgba(24, 18, 45, 0) 75%)`,
						pointerEvents: "none",
					}}
				/>
			</Box>
			<Box sx={{ width: "100%", position: "relative", top: isMobile ? 0 : -90 }}>
				<SummaryCarousel />
			</Box>
		</React.Fragment>
	);
};

export default Home;
