import { Dialog } from "@mui/material";
import Rating from "../OdysseeHome/Rating";
import useOdyssee from "../../../context/odyssee/useOdyssee";

const StepModalRating = ({ isOpen, handleModalClose }) => {
	const { odyssee } = useOdyssee();
	return (
		<Dialog
			open={isOpen}
			onClose={handleModalClose}
			maxWidth={"md"}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "0 0 30px 5px rgb(255, 255, 255, 0.6)",
					borderRadius: 10,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundColor: "rgba(0, 0, 0, 0.9)",
					},
				},
			}}
		>
			<Rating setEditingRating={handleModalClose} odyssee={odyssee} fromStep={true} />
		</Dialog>
	);
};

export default StepModalRating;
